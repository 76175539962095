import Vue from "vue";
import { SellingStock } from "@/models/SellingStock";
import SellingStockItem from "@/views/models/admin/SellingStockItem";

export default class SellingStockApi {
  public readonly root: string = "stock/sellings";

  public index(params: SellingSearchParams) {
    return Vue.axios.get(this.root, { params });
  }

  public delete(id: number) {
    return Vue.axios.delete(this.root + "/" + id);
  }

  public create(sellingStock: SellingStock) {
    return Vue.axios.post(this.root, sellingStock);
  }

  public update(item: SellingStockItem) {
    return Vue.axios.put(`${this.root}/${item.id}`, item);
  }

  public move(SellingStockItem: SellingStockItem) {
    return Vue.axios.put(
      `${this.root}/${SellingStockItem.id}/${SellingStockItem.to}`,
      SellingStockItem
    );
  }

  public sell(item: SellingStockItem) {
    return Vue.axios.post(`${this.root}/selling/${item.id}/${item.to}`, item);
  }

  public reclaim(item: SellingStockItem) {
    return Vue.axios.post(
      `${this.root}/reclaim/${item.org_id}/${item.id}`,
      item
    );
  }
}

export type SellingSearchParams = {
  org_category: number;
  org_id?: number;
  product_category_id: number;
  per_page: number;
  page: number;
};
