





























import { Component, Watch, Emit, Prop } from "vue-property-decorator";
import { Org } from "@/models/Org";
import AdminOrgApi from "@/apis/OrgApi";
import { BaseRepo } from "@/models/BaseRepo";
import BaseView from "@/views/BaseView.vue";
import { UserType } from "@/models/User";

@Component
export default class OrgSearch extends BaseView {
  @Prop() deviceType!: string;
  @Prop() categoryId: number | undefined;

  @Prop({ default: true })
  solo!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  // 신규등록 버튼이 필요할 true값을 넣어주세요.
  @Prop({ default: false })
  appendNew!: boolean;

  @Prop()
  userType!: UserType;

  private selectedOrg = {} as Org;

  private searchKeyword = ""; //검색어

  private serachResult: Org[] = [];

  private api = new AdminOrgApi();

  @Emit()
  private input(org: Org | null) {
    return org;
  }

  //
  @Emit()
  private clickNew() {
    return;
  }

  private onSelectOrg(selected: Org) {
    if (selected != null) {
      this.input(selected);
    }
  }

  @Watch("searchKeyword")
  async onChangeSearchKeyword(cur: string) {
    if (cur == null || cur.length < 1) return;
    if (this.isConnecting) return;

    //선택완료상태
    if (cur.includes("<") || cur.includes(">")) {
      return;
    }

    //완성되지 않은 한글이 있다면 리턴
    const checkHangul = /[ㄱ-ㅎ|ㅏ-ㅣ]/;

    if (checkHangul.test(cur)) return;

    this.isConnecting = true;

    try {
      const repo: BaseRepo = await this.api.searchByKeyword(
        cur,
        this.categoryId,
        this.userType
      );
      const orgs: Org[] = repo.data;

      this.serachResult = orgs;
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
  }

  get orgs() {
    return this.serachResult.map((org: Org) => {
      const sido = org.sido ?? "";
      const sigungu = org.sigungu ?? "";
      let address = "";

      if (sido !== "" && sigungu !== "") {
        address = `<${sido} ${sigungu}>`;
      }

      org.name = `${org.name}${address}`;

      return org;
    });
  }

  clearSelectedOrg() {
    this.selectedOrg = {} as Org;
    this.serachResult = [];
  }

  changeSelectedOrg(org: Org) {
    this.selectedOrg = org;
    this.searchKeyword = org.name;
  }
}
