var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"sub_content_wrap pa-4 pa-sm-4 pa-md-6 container--fluid"},[_c('div',{staticClass:"d-flex mb-4"},[_c('h1',{staticClass:"mr-auto v-heading text-h5 text-sm-h5"},[_vm._v(" 재고현황 - 판매제품 ")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.onClickCreated}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" 제품등록 ")],1)],1),_c('div',{staticClass:"search_form elevation-1"},[_c('div',{staticClass:"inner_content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('org-category',{attrs:{"selectValue":_vm.params.org_category,"solo":true},on:{"input":_vm.onSelectOrgCategory}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.params.org_category != '1'),expression:"params.org_category != '1'"}],attrs:{"cols":"4"}},[_c('org-search',{attrs:{"label":"시설명","hide-details":"","solo":true,"categoryId":_vm.params.org_category},on:{"input":_vm.onSelectOrg}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('product-categories',{attrs:{"solo":true,"categoryType":"sell"},on:{"input":_vm.onSelectProductCategory}})],1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-1 stock_table",attrs:{"headers":_vm.productHeaders,"items":_vm.items,"item-key":"id","loading":_vm.isConnecting,"hide-default-footer":"","items-per-page":_vm.pagenation.per_page},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product ? ((item.product.name) + " (" + (item.product.model_name) + ")") : "")+" ")]}},{key:"item.possible_count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.possible_count),"dark":""}},[_vm._v(" "+_vm._s(item.possible_count)+" ")])]}},(!_vm.params.org_id)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","x-small":"","min-height":"25","dark":""},on:{"click":function($event){return _vm.onClickSell(item)}}},[_vm._v(" 판매 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"min-height":"25","x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.onClickEdit(item)}}},[_vm._v(" 수정 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","x-small":"","dark":"","min-height":"25"},on:{"click":function($event){return _vm.onClickDelete(item)}}},[_vm._v(" 삭제 ")]),_c('v-btn',{attrs:{"color":"green","x-small":"","min-height":"25","dark":""},on:{"click":function($event){return _vm.onClickMove(item)}}},[_vm._v(" 이동 ")])]}}:null,(_vm.params.org_id > 0)?{key:"item.reclaim",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green","x-small":"","dark":"","min-height":"25"},on:{"click":function($event){return _vm.onClickReclaim(item)}}},[_vm._v(" 회수하기 ")])]}}:null],null,true)}),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagenation.last_page,"disabled":_vm.isConnecting},on:{"input":function($event){return _vm.loadDatas($event)}},model:{value:(_vm.pagenation.current_page),callback:function ($$v) {_vm.$set(_vm.pagenation, "current_page", $$v)},expression:"pagenation.current_page"}})],1),(_vm.isCreating)?_c('create-form',{ref:"createForm",attrs:{"stock-type":"selling","categories":_vm.productCategoryItems},on:{"submit":_vm.onCreateProduct,"cancel":function($event){_vm.isCreating = false}}}):_vm._e(),(_vm.isEditing)?_c('edit-form',{ref:"editForm",attrs:{"stock-type":"selling","item":_vm.selectedItem},on:{"submit":_vm.onProductUpdate,"cancel":function($event){_vm.isEditing = false}}}):_vm._e(),(_vm.isMoving)?_c('move-form',{ref:"stockMoveForm",attrs:{"stock-type":"selling","move-item":_vm.selectedItem,"selectValue":_vm.params.org_category},on:{"submit":_vm.onProductMove,"cancel":function($event){_vm.isMoving = false}}}):_vm._e(),(_vm.isSelling)?_c('sell-form',{ref:"stockSellForm",attrs:{"stock-type":"sell","sell-item":_vm.selectedItem,"selectValue":_vm.params.org_category},on:{"submit":_vm.onProductSell,"cancel":function($event){_vm.isSelling = false}}}):_vm._e(),(_vm.isReclaim)?_c('reclaim-form',{ref:"stockReclaimForm",attrs:{"stock-type":"sell","reclaim-item":_vm.selectedItem},on:{"cancel":function($event){_vm.isReclaim = false},"submit":_vm.onProductReclaim}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }