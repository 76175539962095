import { UserType } from "@/models/User";
import Vue from "vue";

export default class OrgApi {
  root = "orgs";

  public searchByKeyword(
    keyword: string,
    categoryId?: number,
    type?: UserType
  ) {
    return Vue.axios.get(`${this.root}/search/${keyword}`, {
      params: { categoryId, type },
    });
  }

  public categories(exclude?: number[], type?: UserType) {
    return Vue.axios.get(`${this.root}/categories`, {
      params: { exclude, type },
    });
  }

  // public create(params: any) {
  //   return Vue.axios.post(`${this.root}/`, params);
  // }
}
