export default class ArrayUtil {
  public static findById(arr: Array<any>, target: any) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (target.id == item.id) {
        return item;
      }
    }
  }

  public static removeById(arr: Array<any>, deleted: any) {
    let idx = -1;
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (deleted.id == item.id) {
        idx = i;
        break;
      }
    }
    arr.splice(idx, 1);
  }

  public static replace(arr: Array<any>, oldItem: any, newItem: any) {
    const idx: number = arr.indexOf(oldItem);

    arr.splice(idx, 1, newItem);

    return arr;
  }

  public static replaceById(arr: Array<any>, newItem: any) {
    let idx = -1;

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (newItem.id == item.id) {
        idx = i;
        break;
      }
    }

    if (idx > -1) arr.splice(idx, 1, newItem);

    return idx;
  }

  public static replaceOrPush(arr: any[], item: any) {
    const idx = arr.indexOf(item);

    if (idx > 0) {
      arr.splice(idx, 1, item);
    } else {
      arr.push(item);
    }
  }

  public static replaceOrPushById(arr: any[], item: any) {
    const idx = arr.findIndex((data) => data.id === item.id);

    if (idx > 0) {
      arr.splice(idx, 1, item);
    } else {
      arr.push(item);
    }
  }

  public static remove(arr: Array<any>, item: any) {
    const idx: number = arr.indexOf(item);

    arr.splice(idx, 1);

    return arr;
  }

  public static pushAll(a: Array<any>, b: Array<any>) {
    b.forEach((item) => {
      a.push(item);
    });
  }

  /**
   * oldItem의 배열값을 참조하지 않는 배열을 새로 만들어서 반환
   * @param oldItem : 복사할 배열
   */
  public static deepCopy(arr: Array<any>) {
    return [...arr];
  }

  public static objectCopy(oldItem: any, newItem: any) {
    const item = Object.entries(newItem);

    for (const [key, value] of item) {
      oldItem[key] = value;
    }
  }
}
