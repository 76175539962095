



















import { BaseRepo } from "@/models/BaseRepo";
import BaseView from "@/views/BaseView.vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import ProductApi from "@/apis/product/ProductApi";
import { ProductCategory } from "@/models/Product";

@Component
export default class Products extends BaseView {
  @Prop({ default: false })
  solo!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: "상품선택" })
  title!: string;

  @Prop({ default: true })
  clearable!: boolean;

  @Prop({ default: undefined })
  productCategoryType!: string;

  @Prop({ default: 0 })
  productCategoryId!: number;

  private serachResult: Array<ProductCategory> = [];

  private api = new ProductApi();

  @Emit()
  private input(category_id: any) {
    return category_id;
  }

  private onSelectCategory(selected: any) {
    this.input(selected);
  }

  protected async created() {
    try {
      const params = {
        productCategoryType: this.productCategoryType,
        productCategoryId: this.productCategoryId,
      };
      const repo: BaseRepo = await this.api.getEnabledProducts(params);
      this.serachResult = repo.data;
    } catch (e) {
      this.handleError(e);
    }
  }
}
