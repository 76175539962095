import Vue from "vue";
import { ProductCategory, ProductType } from "@/models/Product";
import ProductItems from "@/views/models/admin/ProductItems";

export default class ProductApi {
  public readonly root: string = "products";

  //enabled 상관없이 모두 가지고옴
  public getProducts(params: any) {
    return Vue.axios.get(`${this.root}`, { params });
  }

  //enabled가 true인것만 가지고옴
  public getEnabledProducts(params: any) {
    return Vue.axios.get(`${this.root}/enabled`, { params });
  }

  //enabled 상관없이 모두 가지고옴
  public getCategories() {
    return Vue.axios.get(`${this.root}/categories`);
  }

  //enabled가 true인것만 가지고옴 삭제예정
  public getEnabledCategory(type: string) {
    return Vue.axios.get(`${this.root}/categories/enabled/${type}`);
  }

  public createCategory(params: CategoryCreateRequestParams) {
    return Vue.axios.post(`${this.root}/categories`, params);
  }

  public updateCategory(params: ProductCategory) {
    return Vue.axios.put(`${this.root}/categories/${params.id}`, params);
  }

  public deleteCategory(id: number) {
    return Vue.axios.delete(`${this.root}/categories/${id}`);
  }

  public createProduct(params: any) {
    return Vue.axios.post(`${this.root}`, params);
  }

  public updateProduct(params: ProductItems) {
    return Vue.axios.put(`${this.root}/${params.id}`, params);
  }

  public enabledProduct(product_id: number) {
    return Vue.axios.put(`${this.root}/"product/enabled/${product_id}`);
  }

  public deleteProduct(id: number) {
    return Vue.axios.delete(`${this.root}/${id}`);
  }
}

export type CategoryCreateRequestParams = {
  name: string;
  type: ProductType;
  enabled: boolean;
};

export type ProductCreateRequestParams = {
  product_category_id: number | undefined;
  name: string;
  model_name: string;
  enabled: boolean;
  remoark: string;
};
