

















import AdminOrgApi from "@/apis/OrgApi";
import { BaseRepo } from "@/models/BaseRepo";
import { Org } from "@/models/Org";
import { UserType } from "@/models/User";
import BaseView from "@/views/BaseView.vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class OrgCategory extends BaseView {
  @Prop({ default: false })
  solo!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: 1 })
  selectValue!: number;

  @Prop()
  excludeValue!: number[];

  @Prop()
  defaultCategory!: Org;

  @Prop()
  userType!: UserType;

  private items: Org[] = [];

  private api = new AdminOrgApi();

  @Emit()
  private input(category_id: any) {
    return category_id;
  }

  private onSelectOrg(selected: any) {
    this.input(selected);
  }

  protected created() {
    this.loadCategory();
  }

  private async loadCategory() {
    this.isConnecting = true;

    try {
      const repo: BaseRepo = await this.api.categories(
        this.excludeValue,
        this.userType
      );
      let orgs: Org[] = repo.data;

      if (this.defaultCategory) {
        orgs = [this.defaultCategory, ...orgs];
      }

      this.items = orgs;
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
  }
}
