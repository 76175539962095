

















import { BaseRepo } from "@/models/BaseRepo";
import BaseView from "@/views/BaseView.vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import ProductApi from "@/apis/product/ProductApi";
import { ProductCategory } from "@/models/Product";

@Component
export default class ProductCategories extends BaseView {
  @Prop({ default: false })
  solo!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: "카테고리 선택" })
  title!: string;

  @Prop({ default: true })
  clearable!: boolean;

  @Prop()
  categoryType!: string;

  @Prop()
  value!: number;

  private categoryId = 0;
  private serachResult: Array<ProductCategory> = [];

  private api = new ProductApi();

  @Emit()
  private input(category_id: number) {
    return category_id;
  }

  @Emit()
  private returnData(serachResult: Array<ProductCategory>) {
    return serachResult;
  }

  private onSelectCategory(selected: any) {
    this.input(selected);
  }

  protected async created() {
    this.categoryId = this.value;

    try {
      const repo: BaseRepo = await this.api.getEnabledCategory(
        this.categoryType
      );
      this.serachResult = repo.data;
      this.returnData(this.serachResult);
    } catch (e) {
      this.handleError(e);
    }
  }
}
