














































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import SellingStockItem from "@/views/models/admin/SellingStockItem";
import SellingApi from "@/apis/stock/SellingStockApi";
import { ValidationObserver } from "vee-validate";
import { BaseRepo } from "@/models/BaseRepo";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationObserver,
  },
})
export default class ReclaimSellingForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: SellingStockItem) {
    return data;
  }
  @Prop() reclaimItem!: SellingStockItem;
  @Prop() StockType!: boolean;

  private sellingApi: SellingApi = new SellingApi();

  private product_name = "";
  private type = "broken";

  protected created() {
    console.log(this.reclaimItem);
  }

  private async save() {
    if (
      this.type == "broken" &&
      this.reclaimItem.moveCount > this.reclaimItem.possible_count
    ) {
      this.$alert("알림", "불량수량보다 많이 이동할 수 없습니다.");
      return false;
    } else if (
      this.type == "reclaim" &&
      this.reclaimItem.moveCount > this.reclaimItem.possible_count
    ) {
      this.$alert("알림", "재고수량보다 많이 이동할 수 없습니다.");
      return false;
    }

    this.isConnecting = true;

    try {
      const repo = await this.sellingApi.reclaim(this.reclaimItem);
      const data: any = repo.data as BaseRepo;
      this.submit(data);
    } catch (e) {
      this.handleError(e);
    } finally {
      this.isConnecting = false;
    }
  }

  private onChangeType(type: string) {
    this.type = type;
  }
}
